import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import * as apiUtility from '../../config/APIUtils';
import { RiDownload2Fill } from "react-icons/ri";

function JsonReportHandler(props) {

    const value = props.report;
    const [rows, setRows] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [apiError, setApiError] = React.useState(false);
    const [reportDropdownValue, setReportDropdownValue] = React.useState(-1);

    React.useEffect(() => {
        setRows(value);
    }, [value])
    const columns = [
        { id: 'fileName', label: 'File Name' },
        {
            id: 'date',
            label: 'Date',

        },
        {
            id: 'totalRecords',
            label: 'Total Records',
            align: 'center',
        },
        {
            id: 'type',
            label: 'Report Type',
            // minWidth: 170,
            align: 'center',
            //format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'desc',
            label: 'Description',
            minWidth: 170,
        },
    ];



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const generateCSV = (header, data, filename) => {
        const csvData = arrayToCsv(header, data);
        download(csvData, filename);
    };





    const arrayToCsv = (headers, data) => {
        const csvRows = [];

        // Getting headers
        const headerValues = headers.map(header => header.label);
        csvRows.push(headerValues.join(','));

        // Getting rows
        for (const row of data) {
            const rowValues = headers.map(header => {
              //  console.log("Header Key : ",header.key,"Header Value : ",row[header.key]);
                const escaped = ('' + row[header.key]).replace(/"/g, '""');
                return `"${escaped}"`;
            });
            csvRows.push(rowValues.join(','));
        }

        return csvRows.join('\n');
    };

    // Function to download the generated CSV as a .csv file.
    const download = (data, fileName) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', 'true');
        a.setAttribute('href', url);
        a.setAttribute('download', fileName + '.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const downloadJsonReport = () => {
        if (reportDropdownValue !== -1) {

            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "course/download-json-report/" + reportDropdownValue;
            apiUtility.fetchAPIDataGET(apiRequest).then(response => {
                const fileName = `${response.data.filename.split('.json')[0]}`;

                setRows(response.data.history);

                const data = response.data.data;
               
                var csvHeader;

                if(reportDropdownValue==='3'){
                    var new_Data = [];
                    for(var i=0;i<data.length;i++){
                        const [year, month, day] = data[i].dob.split('-');
                            const newFormattedDate = `${month}/${day}/${year}`;
                            data[i].dob = newFormattedDate;
                            if(data[i].country=='Canada'){
                                if(data[i].state=='Ontario'){
                                    data[i].state='ON';
                                }
                                else  if(data[i].state=='British Columbia'){
                                    data[i].state='BC'
                                }
                                else if(data[i].state=='Manitoba'){
                                    data[i].state='MB'
                                }
                                else if(data[i].state=='New Brunswick'){
                                    data[i].state='NS'
                                }
                                else if(data[i].state=='Newfoundland and Labrador'){
                                    data[i].state='NL'
                                }
                                else if(data[i].state=='Northwest Territories'){
                                    data[i].state='NT'
                                }
                                else if(data[i].state=='Nova Scotia'){
                                    data[i].state='NS'
                                }
                                else if(data[i].state=='Alberta'){
                                    data[i].state='AB'
                                }
                                else if(data[i].state=='Nunavut'){
                                    data[i].state='NU'
                                }
                                else if(data[i].state=='Prince Edward Island'){
                                    data[i].state='PE'
                                }
                                else if(data[i].state=='Quebec'){
                                    data[i].state='QC'
                                }
                                else if(data[i].state=='Saskatchewan'){
                                    data[i].state='SK'
                                }
                                else if(data[i].state=='Yukon Territory'){
                                    data[i].state='YT'
                                }
        
                            }
                        var tempData = [];
                       
                        for (var j = 0; j < data[i].student_numbers.length; j++) {
                            var dtempData = "";
                            if (data[i].student_numbers.length > 0 && data[i].student_numbers[j].num) {
                                dtempData = data[i].student_numbers[j].num;
                            }
                            
                            // Create a shallow copy of data[i]
                            var tempData = { ...data[i] }; // OR use Object.assign({}, data[i])
                            
                            // Assign the student number to cnum
                            tempData.cnum = dtempData;
                            
                            // Push the modified copy into new_Data
                            new_Data.push(tempData);
                            
                            // Log the individual cnum
                           // console.log(new_Data[new_Data.length - 1].cnum);
                        }
                    }
                   // console.log(new_Data);
                   // data = newData;
                     csvHeader = [
                        { label: "C-NUM", key: "cnum" },
                        { label: "FIRST NAME", key: "firstName" },
                        { label: "MIDDLE NAME", key: "middleName" },
                        { label: "LAST NAME", key: "lastName" },
                        { label: "USERID", key: "user_id" },
                        { label: "DOB", key: "dob" },
                        { label: "GENDER", key: "gender" },
                        { label: "POSTAL CODE", key: "postalCode" },
                        { label: "PROVINCE", key: "state" },
                        { label: "CITY", key: "city" },
                        { label: "COUNTRY", key: "country" },
                        { label: "ADDRESS1", key: "addressLine1" },
                        { label: "ADDRESS2", key: "addressLine2" },
                        { label: "PH. NUMBER", key: "number" },
                        { label: "EMAIL", key: "email" }
                    ];
                    const csvData = arrayToCsv(csvHeader, new_Data);
                download(csvData, fileName);
                }
                else if (reportDropdownValue === '2') {
                    csvHeader = [
                        { label: "FIRST NAME", key: "firstName" },
                        { label: "MIDDLE NAME", key: "middleName" },
                        { label: "LAST NAME", key: "lastName" },
                        { label: "USERNAME", key: "userName" },
                        { label: "USERID", key: "user_id" }
                       ];
                       const csvData = arrayToCsv(csvHeader, data);
                download(csvData, fileName);
                }
                else if (reportDropdownValue === '1') {
                    csvHeader = [
                        { label: "C-NUM", key: "cnum" },
                        { label: "FIRST NAME", key: "firstName" },
                        { label: "MIDDLE NAME", key: "middleName" },
                        { label: "LAST NAME", key: "lastName" },
                        { label: "USERNAME", key: "userName" },
                        { label: "USERID", key: "user_id" }
                      ]
                      const csvData = arrayToCsv(csvHeader, data);
                      download(csvData, fileName);
                } else {
                    var new_Data = [];
                    for(var i=0;i<data.length;i++){
                        var tempData = [];
                       
                        for (var j = 0; j < data[i].student_numbers.length; j++) {
                            var dtempData = "";
                            if (data[i].student_numbers.length > 0 && data[i].student_numbers[j].num) {
                                dtempData = data[i].student_numbers[j].num;
                            }
                            
                            // Create a shallow copy of data[i]
                            var tempData = { ...data[i] }; // OR use Object.assign({}, data[i])
                            
                            // Assign the student number to cnum
                            tempData.cnum = dtempData;
                            
                            // Push the modified copy into new_Data
                            new_Data.push(tempData);
                        }
                    }
                    csvHeader = [
                        { label: "C-NUM", key: "cnum" },
                        { label: "FIRST NAME", key: "firstName" },
                        { label: "MIDDLE NAME", key: "middleName" },
                        { label: "LAST NAME", key: "lastName" },
                        { label: "USER ID", key: "user_id" },
                        { label: "DOB", key: "dob" },
                        { label: "COURSE ID", key: "course_id" },
                        { label: "COURSE VERSION CODE", key: "courseVersion" },
                        { label: "TRAINING PROVIDER", key: "tpName" },
                        { label: "ORG ID", key: "orgId" },
                        { label: "ORG NAME", key: "orgName" },
                        { label: "HOME ID", key: "homeId" },
                        { label: "HOME NAME", key: "homeName" },
                        { label: "REGISTRATION DATE", key: "completion_date" }
                    ];
                    const csvData = arrayToCsv(csvHeader, new_Data);
                    download(csvData, fileName);
                }
                
            })
                .catch((error) => {
                    setApiError(true);
                });
        }
    };

    return (
        <Container fluid>
            <Modal centered {...props} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Report Log
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <Row>
                        <Col xs={0} md={4}></Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col xs={0} md={10}>
                                    <FloatingLabel
                                        controlId="floatingHome"
                                        label="Report Type"
                                        size="md"
                                    >
                                        <Form.Select aria-label="Floating label select example" value={reportDropdownValue} onChange={(e) => setReportDropdownValue(e.target.value)}>
                                            <option value="-1" key="-1"> -- Please select report type --  </option>
                                            <option value="1" key="1">Users First & Last name with CNUM</option>
                                            <option value="2" key="2">Users First & Last name without CNUM</option>
                                            <option value="3" key="3">Users for account creation with consent</option>
                                            <option value="4" key="4">Users approved training report</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                                <Col xs={0} md={2}>
                                    <Button variant="primary" style={{ "marginTop": "0.5pc" }} float="left" onClick={() => {
                                        downloadJsonReport();
                                    }}><RiDownload2Fill style={{ "fontSize": "1.5pc" }} /></Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={4}>

                        </Col>
                    </Row>
                    <br></br>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table" size="small">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.fileName}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.fileName} align={column.align}>
                                                                {column.format && typeof value === 'number'
                                                                    ? column.format(value)
                                                                    : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Modal.Body>
            </Modal>
        </Container>


    );
}
export default JsonReportHandler;


