import React, {useState} from 'react';
import { Form, Row, Button, FormText, Modal, Container } from 'react-bootstrap';
import * as apiUtility from '../../config/APIUtils';
import { SlPaperPlane } from "react-icons/sl";
import { TbSquareRoundedNumber1,TbSquareRoundedNumber2, TbSquareRoundedNumber3, TbSquareRoundedNumber4, TbSquareRoundedNumber5, TbXboxX } from "react-icons/tb";

const passVerificationError ={
    isLengthy: false,
    hasUpper: false,
    hasLower: false,
    hasNumber: false,
    hasSpecChar: false,
    confirmPassword : false
}


function ForgotPassword(props) {

    const [username, setUsername] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailId, setEmailId] = useState("");
    const [passwordError, setPasswordError] = useState(passVerificationError);


    const handleOnChange = e => {
        const{name, value} = e.target

        if(name === 'oldPassword'){
            setOldPassword(value);
        }

        if(name === 'username'){
            setUsername(value);
        }

        if(name === 'confirmPassword'){
            setConfirmPassword(value);
            setPasswordError({...passwordError, confirmPassword : newPassword === value});
        }
        if(name === "newPassword"){
            setNewPassword(value);
            const isLengthy = value.length >= 8;
            const hasUpper = /[A-Z]/.test(value);
            const hasLower = /[a-z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            const hasSpecChar = /[@,#,$,%,&,*,!]/.test(value);

            setPasswordError({...passwordError, isLengthy, hasLower, hasUpper, hasNumber, hasSpecChar});
        }
    };

    const triggerEmail = async () => {
        setEmailId("");
        if(username === ""){
            setEmailId("Please enter your username");
        }else{
            //const json = JSON.stringify({ login_id: username });
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "login/mail-temp-password";
            apiRequest.data = { "login_id": username };
            await apiUtility.sendAsyncAPIDataPOST(apiRequest)
            // axios.post(BASE_API_URL + 'login/mail-temp-password', json, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // })
            .then(res => {
                if(res.data.error !== "" && res.data.error === false){
                    setEmailId("Temporary password sent : "+res.data.emailId);
                }else{
                    setEmailId(res.data.error);
                }
                
            }).catch(err=>{
                setEmailId("Something went wrong!");
                console.log("Error: ", err);
            });
        }
    }

    const handleOnSubmit = async e => {
        e.preventDefault();
        // const json = JSON.stringify({ login_id: username , "oldPassword": oldPassword, "newPassword": newPassword, "istemp":true});
        // const res = await axios.post(BASE_API_URL+"login/change-password", json, {
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // })
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = "login/change-password";
            apiRequest.data = { "login_id": username , "oldPassword": oldPassword, "newPassword": newPassword, "istemp":true};
            await apiUtility.sendAsyncAPIDataPOST(apiRequest).then(res => {
            //setEmailId("Temporary password sent at "+res.data.emailId);
            if(res.data.msg === "Password updated successfully!"){
                props.handleUpdateCallback(res.data.msg);
            }else{
                setEmailId(res.data.msg);
            }
        }).catch(err=>{
            setEmailId("Something went wrong!");
            console.log("Error: ", err);
        });
    }

return (
    
        <Modal {...props} centered backdrop="static" style={{"width":"40%","top":"0%","left":"30%"}} keyboard={false}>
            <Modal.Body>
                <Form>
                    <Container style={{"width":"100%"}}>
                    <Row className="text-left" style={{"color":"#0f6fc5"}} >
                        <h5 className="text-left">Updated Password!</h5>
                        <hr></hr>
                    </Row>
                    <Container style={{"width":"90%"}}>
                        <Row>
                            <Form.Group as={Row}  className="m-3" controlId="formGridPassword">
                                <Form.Label className="text-left"> Username</Form.Label>
                                <Form.Control type="input" name="username" onChange={handleOnChange} value={username} required placeholder="Enter your username" />
                            </Form.Group>
                        </Row>
                        <br></br>
                    
                   
                        <Row className="text-center">
                            {/* <Button onClick={()=>triggerEmail()}>Generate password</Button> */}
                            <a enable-button-pointers={true} style={{"color":"#0f6fc5","cursor":"pointer"}} class="pe-auto" onClick={()=>triggerEmail()}><SlPaperPlane/>&nbsp;Email me a temporary password</a>
                            <p className='text-muted'> {emailId}</p>
                        </Row>
                        
                        <Row className='text-center'>
                            <Form.Group as={Row} className="m-3" controlId="formGridPassword">
                                <Form.Control style={{"width":"100%"}} type="password" name="oldPassword" onChange={handleOnChange} value={oldPassword} required placeholder="Enter your temporary password" />
                            </Form.Group>
                            <Form.Group as={Row} className="m-3" controlId="formGridPassword">
                                <Form.Control style={{"width":"100%"}} type="password" name="newPassword" onChange={handleOnChange} value={newPassword} required placeholder="New password" />
                            </Form.Group>
                            <Form.Group as={Row} className="m-3" controlId="formGroupPassword">
                                <Form.Control style={{"width":"100%"}} type="password" name="confirmPassword" onChange={handleOnChange} value={confirmPassword} required placeholder="Confirm your new password" />
                            </Form.Group>
                        </Row>
                        <FormText as={Row} className='text-left'>
                            <ul className='mb-4'>
                            {!passwordError.confirmPassword && (
                                <ol className='text-danger'><TbXboxX/> &nbsp;Password mismatch!</ol>
                            )}
                            <ol className={passwordError.isLengthy ? "text-success" : "text-danger"}><TbSquareRoundedNumber1/> &nbsp;Min 8 characters </ol>
                            <ol className={passwordError.hasLower ? "text-success" : "text-danger"}><TbSquareRoundedNumber2/> &nbsp;At least one lower case </ol>
                            <ol className={passwordError.hasUpper ? "text-success" : "text-danger"}><TbSquareRoundedNumber3/> &nbsp;At least one upper case </ol>
                            <ol className={passwordError.hasNumber ? "text-success" : "text-danger"}><TbSquareRoundedNumber4/> &nbsp;At least one number </ol>
                            <ol className={passwordError.hasSpecChar ? "text-success" : "text-danger"}><TbSquareRoundedNumber5/> &nbsp;At least one special character </ol>
                            </ul>
                        </FormText>
                    </Container>
                   
                    
                    </Container>
                </Form>
                
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn btn-secondary mr-auto' onClick={props.onHide}>
                    Cancel
                </Button>
                <Button type="submit" disabled={Object.values(passwordError).includes(false)} onClick={handleOnSubmit} className='btn btn-primary mr-auto'>
                    Update password
                </Button>
            </Modal.Footer>
        </Modal>

);}
export default ForgotPassword;